
import './index.css';
import { UserLogin } from './components/UserLogin';
import { Datalist } from './components/Datalist';
import { ResetPassword } from './components/ResetPassword';
import { BrowserRouter, Route, Routes } from "react-router-dom";

// import { Switch } from 'react-router';


const App = () => {
  return (
    <div >

      <BrowserRouter>
        {/* <Switch> */}
        <Routes>
          <Route path='/' element={<Datalist />} />
          <Route path='/login' element={< UserLogin />} />
          <Route path="/reset" element={<ResetPassword />} />
        </Routes>
        {/* </Switch> */}
      </BrowserRouter>
    </div>
  );
};

export default App;