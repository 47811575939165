import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const ResetPassword = () => {
    const [newpass, setNewpass] = useState("");
    let navigate = useNavigate();
    const newpassword = (e) => {
        // console.log(e.target.value);
        setNewpass(e.target.value);
    };

    useEffect(() => {
        if (!JSON.parse(localStorage.getItem("user"))) {
            navigate("/login");
        }
        if (JSON.parse(localStorage.getItem("user"))) {
            if (JSON.parse(localStorage.getItem("user"))?.status === "error") {
                navigate("/login");
            }
        }
    }, []);

    const changePassword = (e) => {
        e.preventDefault();
        // console.log("hello", e)
        let userdetails = JSON.parse(localStorage.getItem("user"));
        var data = JSON.stringify({
            password: newpass,
        });

        var config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: "https://crmls-compliance-api.styldod.com/reset-password",
            headers: {
                Authorization: `Bearer ${userdetails.token}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                alert("Your password has been changed successfully!");
                localStorage.removeItem("user");
                navigate("/login");
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <div
            // style={{
            //     width: "100%",
            //     height: "100vh",
            //     backgroundColor: "white",
            //     border: "1px solid black",
            //     display: "flex",
            //     alignItems: "center",
            //     justifyContent: "center",
            //     flexDirection: "column",
            // }}
            className="resetBox"
        >
            <div>
                <h1
                    style={{
                        fontSize: "28px",
                        lineHeight: "58px",
                        color: "black",
                    }}
                >
                    Reset Password
                </h1>
            </div>
            <div className="resetPassw">
                <div className="formBody">
                    <form onSubmit={changePassword}>
                        <div className="resetInputfield">
                            <label
                                style={{
                                    marginRight: "25px",
                                    fontSize: "15px",
                                    color: "black",
                                }}
                            >
                                New Password:
                            </label>
                            <input
                                placeholder="New Password"
                                type="password"
                                className="restinput"
                                onChange={newpassword}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button
                                style={{
                                    width: "328px",
                                    padding: "10px",
                                    color: "white",
                                    backgroundColor: "#3e66bd",
                                    borderRadius: "8px",
                                    border: "none",
                                    marginTop: "30px",
                                    fontFamily: "poppins",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                }}
                            >
                                Reset Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
