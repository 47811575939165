// import axios from "axios";
// import { useState,useEffect } from "react";

export const SummeryCard = ({ name, number, pics }) => {
    // const [number,setNumber] = useState('');
    // async  function summeryData() {
    //     const response = await fetch(
    //         `https://crmls-compliance-api.styldod.com/summary?start_date=2023-02-27&end_date=2023-02-28`
    //     );
    //     const result = await response.json();
    //     setNumber(result.data[0].Total);
    // }

    //     useEffect(() => {
    //         summeryData();
    //     }, []);
    return (
        <>
            <div className="summeryCard">
                <div className="summerycardimg">
                    <img src={pics} alt="img" className="cardImg" />
                </div>
                <div className="summeyDetails">
                    <p className="summerycardHeading">{name}</p>
                    <p className="summerycardResult">{number}</p>
                </div>
            </div>
        </>
    );
};
