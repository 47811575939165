import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
} from "react";
import "../App.css";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { Bars } from "react-loader-spinner";
// import { DateRangeComp } from "./DateRangeComp";
// import { DateRangepicker } from "./DateRangepicker";
import { DateRangePicker } from "rsuite";
import { Header } from "./Header";
// import { SummeryCard } from "./components/SummeryCard";
import { SummeryCard } from "./SummeryCard";
import all from "../image/all.png";
// import { ImageRenderer } from "./ImageRenderer";
import warning from "../image/warning.png";
import download from "../image/download.png";
import dupulicate from "../image/duplicate.png";
import { useNavigate } from "react-router-dom";
import { addDays, format } from "date-fns";
// import { SummeryModal } from "./SummeryModal";
const filter_list = {
    all: {
        payload: {},
        value: "All",
        key: "all",
    },
    face: {
        payload: {},
        value: "Human",
        key: "person",
    },
    "face_in_PF/Mirror": {
        payload: {},
        value: "Face in PF/Mirror",
        key: "face_visible_in_photoframe_or_mirror",
    },
    animal: {
        payload: {},
        value: "Animal",
        key: "animal",
    },
    license_plate: {
        payload: {},
        value: "License Plate",
        key: "license_plate",
    },
    url: {
        payload: {},
        value: "URL",
        key: "url",
    },
    email: {
        payload: {},
        value: "Email",
        key: "email",
    },
    phone: {
        payload: {},
        value: "Phone",
        key: "phone",
    },
    advertisement: {
        payload: {},
        value: "Advertisement",
        key: "advertisement",
    },
    signboard: {
        payload: {},
        value: "Signboard",
        key: "signboard",
    },
    qr_code: {
        payload: {},
        value: "QR code",
        key: "qr_code",
    },
    brand: {
        payload: {},
        value: "Brand",
        key: "brand",
    },
    nsfw: {
        payload: {},
        value: "NSFW",
        key: "nsfw",
    },
};

const pagesList = [20, 30, 40, 50];

export const Datalist = () => {
    const [userDe, setUserDe] = useState({});
    const [number, setNumber] = useState("Loading...");
    const [duplicateImg, setDuplicateImg] = useState("Loading...");
    const [feedback, setFeedback] = useState("");
    const [selectedbtn, setSelectedbtn] = useState("All");
    const [noncompliant, setNonCompliant] = useState("Loading...");
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const [loading, setLoading] = useState(false); // Set loader for filter data.
    let navigate = useNavigate();
    const [currentItem, setCurrentItem] = useState({ key: "", count: -1 });
    const gridRef = useRef();
    const pagination = true;
    let mediakey = "";
    const [paginationPageSize, setpaginationPageSize] = useState(50);
    const [startingdate, setStartingdate] = useState(
        format(new Date(), "yyyy-MM-dd")
    );
    const [dateValue, setDateValue] = useState();
    const [endingdate, setEndingdate] = useState(
        format(addDays(new Date(), 1), "yyyy-MM-dd")
    );
    useEffect(() => {
        if (!JSON.parse(localStorage.getItem("user"))) {
            navigate("/login");
        }
        if (JSON.parse(localStorage.getItem("user"))) {
            if (JSON.parse(localStorage.getItem("user"))?.status === "error") {
                navigate("/login");
            }
        }
    }, []);

    const [columnDefs, setColumnDefs] = useState([
        { field: "ListingId", width: 150 },
        { field: "SystemID", width: 110 },
        {
            field: "Images",
            width: 130,
            cellRendererFramework: (params) => (
                <div>
                    <button
                        onClick={() => OpenImagePreview(params)}
                        className="openBtn"
                        style={{
                            cursor: "pointer",
                            fontFamily: "Poppins",
                        }}
                    >
                        Open Image
                    </button>
                </div>
            ),
        },
        {
            field: "Review",
            width: 120,
            cellRendererFramework: (params) => (
                <>
                    <div>
                        <select
                            className="imgStatus"
                            style={{ fontFamily: "poppins" }}
                            defaultValue={params["value"]}
                            onChange={(e) => getSelectedValue(e, params)}
                        >
                            <option value="pending">pending</option>
                            <option value="approved">approved</option>
                            <option value="rejected">rejected</option>
                        </select>
                    </div>
                </>
            ),

            // valueGetter: (params) => {
            //     console.log("Test", params.data.Review);
            //     // params.data.Review = params.data.Review;
            //     return params.data.name;
            // },
            // valueSetter: (params) => {
            //     console.log("Maiku", params.data.Review, params);
            //     params.data.Review = params.newValue;
            //     return false;
            // },
        },
        { field: "MediaKey", hide: true },
        { field: "human", width: 100, floatingFilter: false },
        { field: "face in PF/Mirror", width: 130, floatingFilter: false },
        { field: "animal", width: 100, floatingFilter: false },
        { field: "License Plate", width: 130, floatingFilter: false },

        { field: "URL", width: 100, floatingFilter: false },
        { field: "email", width: 100, floatingFilter: false },
        { field: "phone", width: 100, floatingFilter: false },
        { field: "advertisement", width: 150, floatingFilter: false },
        { field: "signboard", width: 130, floatingFilter: false },
        { field: "brand", width: 100, floatingFilter: false },
        { field: "QR code", width: 120, floatingFilter: false },
        { field: "NSFW", width: 100, floatingFilter: false },
        {
            field: "MediaURL",
        },
        { field: "Response" },
    ]);

    const onBtShowLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const onBtHide = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => ({
        // filter: true,
        sortable: true,
        selectable: true,
        master: true,
        expanded: true,
        editable: true,
    }));

    //open image preview
    const OpenImagePreview = (params) => {
        window.open(params.data.MediaURL, "_blank");
    };
    //selected value for image
    const getSelectedValue = (e, params) => {
        console.log("params", params);
        params.data.Review = e.target.value;
        imageFeedback(e.target.value);
    };
    // Example of consuming Grid Event
    const cellClickedListener = useCallback((event) => {
        // setMediakey(event.data.MediaKey);
        mediakey = event.data.MediaKey;
    }, []);

    // getting data for total images
    async function summeryData() {
        let userdetails = JSON.parse(localStorage.getItem("user"));

        var config = {
            method: "get",
            maxBodyLength: Infinity,
            headers: {
                Authorization: `Bearer ${userdetails.token}`,
            },
        };
        const response = await fetch(
            `https://crmls-compliance-api.styldod.com/summary?start_date=${startingdate}T00:00:00&end_date=${endingdate}T23:59:59`,
            config
        );

        const result = await response.json();
        if (result.status === "error") {
            if (
                result.message === "Invalid token" ||
                result.message === "Authorization header is invalid" ||
                result.message === "Authorization header is missing"
            ) {
                localStorage.removeItem("user");
                navigate("/login");
            }
        } else {
            // onBtShowLoading();
            setNumber(result.data[0].Total);
            setDuplicateImg(result.data[0].Duplicates);
            // let comdat = result.data[0];
            // let ml = Object.values(comdat);
            // let sum = ml.reduce((a,b) => a+b,0);
            setNonCompliant(result.data[0].NonCompliant);
        }
    }

    // Example load data from sever
    async function gettingData() {
        // window.location.reload(false);
        let userdetails = JSON.parse(localStorage.getItem("user"));
        var config = {
            method: "get",
            maxBodyLength: Infinity,
            headers: {
                Authorization: `Bearer ${userdetails.token}`,
            },
        };
        const response = await fetch(
            `https://crmls-compliance-api.styldod.com/data?start_date=${startingdate}T00:00:00&end_date=${endingdate}T23:59:59`,
            config
        );
        onBtShowLoading();

        // setLoading(true);
        const result = await response.json();
        if (result.status === "error") {
            // alert(result.message);
            onBtHide();
            setLoading(false);
            if (
                result.message === "Invalid token" ||
                result.message === "Authorization header is invalid" ||
                result.message === "Authorization header is missing"
            ) {
                localStorage.removeItem("user");
                navigate("/login");
            }
        } else {
            onBtHide();
            // setLoading(false);

            result.data.map((item) => {
                item["human"] = item["person"];
                delete item["person"];
                item["face in PF/Mirror"] =
                    item["face_visible_in_photoframe_or_mirror"];
                delete item["face_visible_in_photoframe_or_mirror"];
                item["URL"] = item["url"];
                delete item["url"];
                item["NSFW"] = item["nsfw"];
                delete item["nsfw"];
                item["QR code"] = item["qr_code"];
                delete item["qr_code"];
                item["SystemID"] = item["OriginatingSystemID"];
                delete item["OriginatingSystemID"];
                item["License Plate"] = item["license_plate"];
                delete item["license_plate"];
                item["Review"] = item["Status"];
                delete item["Status"];
                return item;
            });

            setRowData(result.data);

            // onBtHide();
        }
    }

    useEffect(() => {
        setNumber("Loading...");
        setNonCompliant("Loading...");
        setDuplicateImg("Loading...");
        if (!JSON.parse(localStorage.getItem("user"))) {
            navigate("/login");
        }
        if (JSON.parse(localStorage.getItem("user"))) {
            if (JSON.parse(localStorage.getItem("user"))?.status === "error") {
                navigate("/login");
            } else {
                setSelectedbtn("All");
                setCurrentItem({ key: "", count: -1 });

                // window.location.reload(false);
                gettingData();
                summeryData();

                // onBtHide();
            }
        }
    }, [startingdate, endingdate]);

    const pageSizeChange = (val) => {
        setpaginationPageSize(val);
    };

    const setFilterHandler = async (item) => {
        setLoading(true);
        setSelectedbtn(item.value);
        let userdetails = JSON.parse(localStorage.getItem("user"));

        var config = {
            method: "get",
            maxBodyLength: Infinity,
            headers: {
                Authorization: `Bearer ${userdetails.token}`,
            },
        };
        const response = await fetch(
            item.key === "all"
                ? `https://crmls-compliance-api.styldod.com/data?start_date=${startingdate}T00:00:00&end_date=${endingdate}T23:59:59`
                : `https://crmls-compliance-api.styldod.com/filter?start_date=${startingdate}T00:00:00&end_date=${endingdate}T23:59:59&filter=${item.key}`,
            config
        );
        const result = await response.json();

        if (result.status === "error") {
            if (
                result.message === "Invalid token" ||
                result.message === "Authorization header is invalid" ||
                result.message === "Authorization header is missing"
            ) {
                localStorage.removeItem("user");
                navigate("/login");
            }
        } else {
            setCurrentItem({ key: item.key, count: result.data.length });
            result.data.map((item) => {
                item["human"] = item["person"];
                delete item["person"];
                item["face in PF/Mirror"] =
                    item["face_visible_in_photoframe_or_mirror"];
                delete item["face_visible_in_photoframe_or_mirror"];
                item["URL"] = item["url"];
                delete item["url"];
                item["NSFW"] = item["nsfw"];
                delete item["nsfw"];
                item["QR code"] = item["qr_code"];
                delete item["qr_code"];
                item["SystemID"] = item["OriginatingSystemID"];
                delete item["OriginatingSystemID"];
                item["License Plate"] = item["license_plate"];
                delete item["license_plate"];
                item["Review"] = item["Status"];
                delete item["Status"];
                return item;
            });

            setRowData(result.data);

            setLoading(false);
        }
    };

    const exportCsv = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const imageFeedback = (status) => {
        let userdetails = JSON.parse(localStorage.getItem("user"));

        if (userdetails) {
            var data = JSON.stringify({
                media_key: mediakey,
                feedback: status,
            });

            var config = {
                method: "POST",
                maxBodyLength: Infinity,
                url: "https://crmls-compliance-api.styldod.com/feedback",
                headers: {
                    Authorization: `Bearer ${userdetails.token}`,
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    console.log("res", response);
                    // gettingData();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    const [range, setRange] = useState([
        {
            startDay: new Date(),
            startDate: new Date(),
            endDate: addDays(new Date(), 1),
            key: "selection",
        },
    ]);
    const handleDate = (item) => {
        if (item) {
            setStartingdate(`${format(item[0], "yyyy-MM-dd")}`);
            setEndingdate(`${format(item[1], "yyyy-MM-dd")}`);
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <Header />
            <div className="summeryCardDiv">
                <SummeryCard
                    name={"Total Scanned Images"}
                    number={number}
                    pics={all}
                />
                <SummeryCard
                    name={"Suspect Images"}
                    number={noncompliant}
                    pics={warning}
                />
                <SummeryCard
                    name={"Duplicate Images"}
                    number={duplicateImg == 0 ? "" : duplicateImg}
                    pics={dupulicate}
                />
            </div>

            <div className="filterbtndiv">
                <div className="comDate">
                    <DateRangePicker
                        appearance="default"
                        value={dateValue}
                        onChange={handleDate}
                        showOneCalendar
                        style={{ cursor: "pointer", width: "225px" }}
                    />
                </div>

                <div>
                    {Object.keys(filter_list).map((item, id) => {
                        return (
                            <button
                                className={
                                    selectedbtn === filter_list[item].value
                                        ? "filterbutton selected"
                                        : "filterButton"
                                }
                                key={id}
                                onClick={() =>
                                    setFilterHandler(filter_list[item])
                                }
                                style={{
                                    padding: "8px",
                                    borderRadius: "4px",
                                    border: "1px solid grey",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                    fontSize: "12px",
                                    fontFamily: "Poppins",
                                    marginRight: "8px",
                                }}
                            >
                                {filter_list[item].value}
                                {currentItem.key === filter_list[item].key &&
                                    ":(" + currentItem.count + ")"}
                            </button>
                        );
                    })}
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "94%",
                    margin: "auto",
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                        style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            marginRight: "10px",
                            lineHeight: "1",
                            fontFamily: "poppins",
                        }}
                    >
                        Page Size
                    </p>
                    <select
                        value={paginationPageSize}
                        onChange={(e) => pageSizeChange(e.target.value)}
                        style={{
                            marginRight: "20px",
                            fontSize: "12px",
                            cursor: "pointer",
                            fontFamily: "poppins",
                        }}
                    >
                        {/* selected={paginationPageSize==item?true:false}  */}
                        {pagesList.map((item, i) => {
                            return (
                                <option value={item} key={i}>
                                    {item}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                        <button
                            className="exportcsvbtn"
                            onClick={exportCsv}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                backgroundColor: "rgb(255 255 255)",
                                marginLeft: "20px",
                            }}
                        >
                            <img
                                src={download}
                                style={{ width: "18px", marginRight: "10px" }}
                            ></img>
                            <p
                                style={{
                                    fontSize: "13px",
                                    color: "#6189FF",
                                    lineHeight: "1",
                                    fontFamily: "poppins",
                                    fontWeight: "bold",
                                }}
                            >
                                Download CSV
                            </p>
                        </button>
                    </div>
                </div>
            </div>

            <div className="ag-theme-alpine">
                {loading ? (
                    <Bars
                        type="ThreeDots"
                        color="#somecolor"
                        className="Bars"
                    />
                ) : (
                    <AgGridReact
                        ref={gridRef} // Ref for accessing Grid's API
                        pagination={pagination}
                        paginationPageSize={paginationPageSize}
                        rowData={rowData} // Row Data for Rows
                        // rowHeight={170}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Loading...</span>'
                        }
                        columnDefs={columnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        alwaysShowHorizontalScroll={true}
                        alwaysShowVerticalScroll={true}
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        rowSelection="multiple" // Options - allows click selection of rows
                        onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                    />
                )}
            </div>
        </div>
    );
};
