import pic from "../image/styldod.png";
import crmls from "../image/crmls.png";
import user from "../image/user.png";
import cross from "../image/XCircle.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import "./Header.css";
export const Header = () => {
    const [userDetails, setuserDetails] = useState(
        JSON.parse(localStorage.getItem("user"))
    );
    const [username, setUserName] = useState("");
    let navigate = useNavigate();
    // const [newpassWord, setNewPassWord] = useState("");
    const [userdisplay, setUserDisplay] = useState("userStyle");
    // const [resetpass, setRestpass] = useState("resetpassword");
    const [newcross, stNewcross] = useState("nw");
    const showName = () => {
        setUserDisplay("userafterClick");
        stNewcross("nw1");
    };

    const showDisplayName = () => {
        setUserDisplay("userStyle");
        stNewcross("nw");
    };

    useEffect(() => {
        // setUserName(userDetails.data.email);
        // console.log(userDetails.data.name);
        if (localStorage.getItem("user")) {
            setUserName(userDetails.email);
        }
    }, []);

    const userLogout = () => {
        // console.log("hey Hey Hello");
        localStorage.removeItem("user");
        window.location.reload(false);
    };

    const passwordRestfun = () => {
        navigate("/reset");
    };

    // const resetPassword = () =>{
    //     console.log("reset Password");
    //     resetPassword("newresetpassword");
    // }

    // const  newpassword = (event) =>{
    //     setNewPassWord(event.target.value);
    // }

    //     const changedPassword = (e) =>{
    //         e.prevent.default();
    //         let userdetails = JSON.parse(localStorage.getItem("user"));
    //         var data = JSON.stringify({
    //            password: newpassWord
    //         });

    //        var config = {
    //        method: 'post',
    //        maxBodyLength: Infinity,
    //        url: 'https://crmls-compliance-api.styldod.com/reset-password',
    //        headers: {
    //        'Authorization': `Bearer ${userdetails.token}`,
    //        'Content-Type': 'application/json'
    //     },
    //     data : data
    //    };

    //     axios(config)
    //     .then(function (response) {
    //      console.log(JSON.stringify(response.data));
    //      })
    //     .catch(function (error) {
    //     console.log(error);
    //     });
    //     }
    return (
        <>
            <div className="complianceHeader">
                <div>
                    <img src={pic} alt="styldod_logo" className="compLogo" />
                </div>
                <div>
                    <img src={crmls} alt="crmls_logo" className="crmlsLogo" />
                </div>
                <div onClick={showName}>
                    <img src={user} alt="user_image" className="userLogin" />
                </div>
            </div>
            <div className="usernameContainer">
                <div className={userdisplay}>
                    {/* <form onSubmit={changedPassword} style={{marginLeft:"19px"}}>
                        <label>Reset Password:</label>
                        <input placeholder="New Password" type="password" onChange={newpassword} style={{marginRight:"10px", marginTop:"5px",padding:"5px"}}></input>
                        <button style={{cursor:"pointer" ,padding:"5px"}}> Reset</button>
                    </form> */}

                    <button
                        onClick={passwordRestfun}
                        className="resetPasswordbtn"
                    >
                        Reset Password
                    </button>
                    <button onClick={userLogout} className="logoutbutton">
                        Log out
                    </button>
                </div>
                <div
                    // style={{
                    //     position: "relative",
                    //     width: "auto",
                    //     marginTop: "-107px",
                    //     marginLeft: "224px",
                    // }}
                    className="logCross"
                    onClick={showDisplayName}
                >
                    <img
                        src={cross}
                        alt="cross"
                        style={{ width: "20px", cursor: "pointer" }}
                        className={newcross}
                    />
                </div>
            </div>
        </>
    );
};
