import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const UserLogin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [user, setUser] = useState({});

    const emailValue = (e) => {
        setEmail(e.target.value);
    };

    let navigate = useNavigate();
    const passwordValue = (e) => {
        setPassword(e.target.value);
    };

    const CheckEmailAndPassword = (e) => {
        e.preventDefault();
        var data = JSON.stringify({
            email: email,
            password: password,
        });

        var config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://crmls-compliance-api.styldod.com/login",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // setUser(response.data);
                localStorage.setItem("user", JSON.stringify(response.data));
                gettingData();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            if (user.email && user.token) {
                navigate("/");
            }
        }
    }, []);

    const gettingData = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            if (!user.email && !user.token) {
                alert("Invalid Credentials");
            }
        }
        if (user) {
            if (user.email && user.token) {
                navigate("/");
            }
        }
    };

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "white",
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <div>
                <h1 className="loginacc">Login to your account</h1>
            </div>
            <div className="loginBox">
                <div className="formBody">
                    <form onSubmit={CheckEmailAndPassword}>
                        <div>
                            <label style={{ color: "black" }}>Email:</label>
                            <input
                                placeholder="Enter your Email"
                                type="text"
                                // style={{
                                //     width: "320px",
                                //     height: "30px",
                                //     marginBottom: "20px",
                                //     marginLeft: "61px",
                                // }}
                                onChange={emailValue}
                                className="emailInputfield"
                            />
                        </div>
                        <label style={{ marginRight: "32px", color: "black" }}>
                            Password:
                        </label>
                        <input
                            placeholder="Enter your password"
                            type="password"
                            style={{
                                width: "320px",
                                height: "30px",
                                marginBottom: "20px",
                            }}
                            onChange={passwordValue}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button
                                style={{
                                    width: "328px",
                                    padding: "10px",
                                    color: "white",
                                    backgroundColor: "#3e66bd",
                                    borderRadius: "8px",
                                    border: "none",
                                    fontFamily: "poppins",
                                    fontWeight: "bold",
                                    marginTop: "20px",
                                    cursor: "pointer",
                                }}
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
